<template>
  <MobileHeadBar
    v-if="isMobileRef"
    :show-more-games-selector="true"
    title=""
  ></MobileHeadBar>
  <MobileBottomBar v-if="isMobileRef"></MobileBottomBar>
  <div v-if="!useSelectedGameDetail().value?.key">
    <ErrorComponent></ErrorComponent>
  </div>
  <div v-if="useSelectedGameDetail().value?.key" class="game-tab-root">
    <div :class="activeTab==='map'?'map-container': 'tab-content-container'">
      <div v-if="activeTab === 'home'">
        <Homepage></Homepage>
      </div>
      <div v-else-if="activeTab === 'guide'">
        <HomeGuide />
      </div>
      <!-- <div v-else-if="activeTab === 'mods'">
        <HomeMods />
      </div>
      <div v-else-if="activeTab === 'social'">
        <HomeSocial />
      </div> -->
      <div v-else-if="activeTab === 'tier-list'"> 
        <TierPage />
      </div>
      <div v-else-if="activeTab === 'map'">
        <HomeMap />
      </div>
      <div v-else-if="activeTab === 'wallpaper'">
        <WallPaper></WallPaper>
      </div>
      <div v-else class="error-component">
        <ErrorComponent></ErrorComponent>
      </div>
    </div>
  </div>
</template>

<script setup>
import GameUtil from '@/util/game-util.ts';
import { defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
import NetworkMgr from '~/util/network-mgr.ts';
const ErrorComponent = defineAsyncComponent(
  () => import('@/components/error/error-component.vue')
);
const TierPage = defineAsyncComponent(
    () =>
        import(
            '@/components/games/tier/home-tier.vue'
            )
);
const MobileHeadBar = defineAsyncComponent(
  () => import('@/components/nav-bar/mobile-head-bar.vue')
);
const MobileBottomBar = defineAsyncComponent(
  () => import('@/components/nav-bar/mobile-bottom-bar.vue')
);
const Homepage = defineAsyncComponent(
  () => import('@/components/games/homepage/home-page.vue')
);
const HomeGuide = defineAsyncComponent(
  () => import('@/components/games/guide/home-guide.vue')
);
// const HomeMods = defineAsyncComponent(
//   () => import('@/components/games/mod/home-mods.vue')
// );
const HomeMap = defineAsyncComponent(
  ()=> import('@/components/games/game-map/map-homepage.vue')
)
// const HomeSocial = defineAsyncComponent(
//   () => import('@/components/games/social/game-social-page.vue')
// );
const WallPaper = defineAsyncComponent(
  ()=> import('@/components/games/wallpaper/home-wallpaper.vue')
) 
const route = useRoute()
const activeTab = route.params.tab || 'home';
const isMobileRef = computed(() => {
  return useIsMobileState().value;
});

const updateGameInfo = async () => {
  var game = route.params.game
  if(game == null) {
    game = 'starfield'
  }
  const response = await NetworkMgr.getInstance().fetch('games/games/detail', {
    key: game,
    site_id: 'SITE_BLACK_MYTH',
  });
  if (response) {
    let gameDetail = response.game;
    useState('selectedGameDetail').value = gameDetail;
    console.log('gameDetail', gameDetail)
  }
};

const gameKey = route.params.game || GameUtil.defaultGameDetail.key;
const savedGameKey = useState('selectedGameDetail').value?.key ?? '';
if (gameKey != savedGameKey) {
  await updateGameInfo();
}
</script>
<style scoped>
.game-tab-root {
  margin-bottom: 0;
}

.tab-content-container {
  width: calc(100vw - 128px);
  margin-left: 64px;
  margin-right: 64px;
}
.map-container {
  margin-top: 50px;
}
.tab-content-container .error-component {
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  top: -20px;
  left: 0px;
}
@media screen and (max-width: 1000px) {
  .game-tab-root {
    margin-top: 52px;
    margin-bottom: 56px;
  }

  .tab-content-container {
    margin-left: 0px;
    margin-right: 0px;
    width: 100vw;
  }

}

@media screen and (min-width: 1666px) {
  .tab-content-container {
    width: 1538px;
    margin-left: calc((100vw - 1538px) / 2);
    margin-right: calc((100vw - 1538px) / 2);
  }
}
</style>
