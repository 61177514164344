export default class GameUtil {
  static defaultGameDetail = {
    id: 10058,
    key: 'black-myth-wukong',
    logo: '',
    bigLogo:
      '',
    name: 'black-myth',
    tabs: [
      { name: 'home', key: 'home' },
      { name: 'wallpaper', key: 'wallpaper' },
    ],
    description:
      '',
    externalLink: '',
    mediaList: []
  };
}
